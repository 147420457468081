<template>
  <v-col
    cols="12"
  >
    <div class="d-flex mb-2 justify-end align-center" v-if="filteredItemsByYear.length">
      <v-menu>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            outlined
            color="primary"
            v-bind="attrs"
            v-on="on"
            :prepend-icon="mdiMenuDown"
          >
            {{ currentYear }}
            <v-icon right>{{ mdiMenuDown }}</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in years"
            :key="index"
            @click="setYear(item)"
          >
            <v-list-item-title>{{ item }}</v-list-item-title>
          </v-list-item>
        </v-list>
    </v-menu>
    </div>
    <v-card>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Suche nach Sammlungsname, Datum, Liturgischer Tag oder Summe"
          hide-details
          single-line
        ></v-text-field>
      </v-card-title>
      <v-data-table
        id="disbursement-orders-table"
        :headers="headers"
        :items="filteredItemsByYear"
        :item-class="(item) => item.disbursement.state === DISBURSEMENT_STATE.CANCELLED ? 'cancelled' : ''"
        :search="search"
        :custom-filter="customSearch"
        :sort-by="['end']"
        :sort-desc="[true]"
        item-key="id"
        dense
      >
        <template v-slot:item.name="{ item }">
          <v-tooltip bottom v-if="item.name">
            <template v-slot:activator="{ on, attrs }">
              <span
                v-bind="attrs"
                v-on="on"
              >
                {{ item.name.length > 22 ? item.name.substring(0, 45) + '...' : item.name }}
              </span>
            </template>
            <span>{{ item.name }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.type="{ item }">
          <span>{{ $t(`dashboard.collectionType.${item.type}`) }}</span>
        </template>
        <template v-slot:item.start="{ item }">
          <span>{{ item.start | isoToHuman('L') }}</span>
        </template>
        <template v-slot:item.end="{ item }">
          <span>{{ item.end | isoToHuman('L') }}</span>
        </template>
        <template v-slot:item.day="{ item }">
          <span>{{ item.day || '- -' }}</span>
        </template>
        <template v-slot:item.disbursement.sum_amount_total="{ item }">
          <span>{{ (item.disbursement.sum_amount_total || (Number(item.disbursement.sum_amount_cash) + Number(item.disbursement.sum_amount_pos))) | amount }}&nbsp;{{item.disbursement.currency || "??"}}</span>
        </template>
        <template v-slot:item.disbursement.sum_amount_cash="{ item }">
          <span>{{ item.disbursement.sum_amount_cash || 0 | amount }}&nbsp;{{item.disbursement.currency || "??"}}</span>
        </template>
        <template v-slot:item.disbursement.sum_amount_pos="{ item }">
          <span>{{ item.disbursement.sum_amount_pos || 0 | amount }}&nbsp;{{item.disbursement.currency || "??"}}</span>
        </template>
        <template v-slot:item.disbursement.sum_amount_ecom="{ item }">
          <span>{{ item.disbursement.sum_amount_ecom || 0 | amount }}&nbsp;{{item.disbursement.currency || "??"}}</span>
        </template>
        <template v-slot:item.state="{ item }">
          <span  >
            <v-tooltip v-if="item.disbursement.state === DISBURSEMENT_STATE.TRANSFERRED"  bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  color="green darken-2"
                >
                  {{mdiCheckCircle}}
                </v-icon>
              </template>
              <span>erledigt</span>
            </v-tooltip>

            <v-tooltip v-else-if="item.disbursement.state === DISBURSEMENT_STATE.IN_PROGRESS"  bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  :bind="attrs"
                  v-on="on"
                  color="orange"
                >
                  {{mdiAlert}}
              </v-icon>
              </template>
              <span>zu bearbeiten </span>
            </v-tooltip>

            <v-tooltip v-else  bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  color="gray"
                >
                  {{mdiMinusCircle}}
                </v-icon>
              </template>
              <span>abgewählt</span>
            </v-tooltip>
          </span>
        </template>
        <template v-slot:item.add_cash_action="{ item }">
          <div
            class="d-flex justify-end"
            v-if="item.disbursement.state !== DISBURSEMENT_STATE.CANCELLED"
          >
            <v-tooltip
              v-if="item.disbursement.stop_cash_payments === null"
              bottom
              :disabled="false"
            >
              {{ allowCashPayments ? 'Barsammlung' : "Bargeldmodul fehlt" }}
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    :disabled="!allowCashPayments"
                    class="text-caption"
                    small
                    color="primary"
                    @click="openAddCashCollectionDialog(item)"
                  ><v-icon class="mr-2">$vuetify.icons.cashCollection</v-icon>  </v-btn>
                </span>

              </template>
            </v-tooltip>

          </div>
        </template>
        <template v-slot:item.cancel_action="{ item }">
          <div
            class="d-flex"
            v-if="item.disbursement.state !== DISBURSEMENT_STATE.CANCELLED"
          >
            <v-tooltip
              v-if="item.disbursement.cancellable"
              bottom
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="cancelDisbursementOrder(item.disbursement.id)"
                  icon
                  v-bind="attrs"
                  v-on="on"
                  class="text-caption"
                  small
                  color="primary"
                >
                  <v-icon class="mr-2">{{ mdiTableRemove }}</v-icon>
                </v-btn>
              </template>
              <span>Kollekte abwählen</span>
            </v-tooltip>
          </div>
        </template>
        <template v-slot:item.download_pdf_action="{ item }">
          <div
            class="d-flex"
            v-if="item.disbursement.state !== DISBURSEMENT_STATE.CANCELLED"
          >
            <v-btn
              v-if="(item.disbursement.documents && item.disbursement.documents.length > 0) || (item.disbursement.stop_cash_payments && selectedDisbursementOrder?.disbursement?.id === item.disbursement.id && loading)"
              :loading="selectedDisbursementOrder?.disbursement?.id === item.disbursement.id && !!loading"
              @click="$emit('downloadPdf', {url:item.disbursement.documents[0], date: isoToHuman(item.disbursement.stop_cash_payments, 'YYYY-MM-DD') })"
              class="text-caption"
              small
              color="primary "
            >
              <v-icon class="mr-2">mdi-file-download</v-icon>
              PDF
            </v-btn>
            <v-btn
              v-if="item.disbursement.stop_cash_payments === null"
              class="text-caption"
              small
              color="primary"
              @click="openStopCashDialog(item)"
            >
              <v-icon class="mr-2">mdi-check-circle</v-icon>
              abschließen
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </v-card>

    <StopCashDialog
      v-if="selectedDisbursementOrder"
      v-model="stopCashDialog"
      :disbursement-order="selectedDisbursementOrder"
      @close="stopCashDialog = false; refreshDisbursementOrder()"
    />
    <add-cash-collection-dialog
          v-if="selectedDisbursementOrder"
          v-model="addCashCollectionDialog"
          :organization="currentOrganization"
          :collection="selectedDisbursementOrder"
          @payment-success="refreshDisbursementOrder"
        />
  </v-col>
</template>

<script>
import {
  monthMappingDe,
  monthMappingEn
} from "@/lib/search-by-month"
import {
  mdiEmailOpenOutline,
  mdiEmailOutline,
  mdiFileExcel,
  mdiHelpBox,
  mdiProgressClock,
  mdiArchiveOutline,
  mdiTextBoxMultipleOutline,
  mdiMenuDown,
  mdiCheckCircle,
  mdiAlert,
  mdiTableRemove,
  mdiMinusCircle
} from "@mdi/js"
import StopCashDialog from "./StopCashDialog.vue"
import AddCashCollectionDialog from "@/components/collection/AddCashCollectionDialog.vue"
import { mapGetters, mapState } from "vuex"

import { CANCEL_DISBURSEMENT_ORDER, FETCH_DISBURSEMENT_ORDER_SINGLE } from '@/store/action-types'
import DISBURSEMENT_STATE from "@/lib/disbursement-state"
import { isoToHuman } from "@/filters/datetime"

export default {
  name: "EvalDisbursementOrdersTable",
  emits: ["downloadPdf"],
  components: {
    AddCashCollectionDialog,
    StopCashDialog
  },
  props: {
    headers: { type: Array, required: true },
    items: { type: Array },
  },
  data () {
    return {
      isoToHuman,
      DISBURSEMENT_STATE,
      mdiEmailOutline,
      mdiEmailOpenOutline,
      mdiFileExcel,
      mdiHelpBox,
      mdiProgressClock,
      mdiArchiveOutline,
      mdiTextBoxMultipleOutline,
      mdiMenuDown,
      mdiCheckCircle,
      mdiAlert,
      mdiTableRemove,
      mdiMinusCircle,
      selected: [],
      expanded: [],
      search: "",
      currentYear: new Date().getFullYear(),
      stopCashDialog: false,
      addCashCollectionDialog: false,
      selectedDisbursementOrder: null,
    }
  },
  methods: {
    customSearch (value, search, item) {
      search = search.toLowerCase()
      if (Object.keys(monthMappingEn).includes((search))) {
        search = "-" + monthMappingEn[search] + "-"
      }
      if (Object.keys(monthMappingDe).includes(search)) {
        search = "-" + monthMappingDe[search] + "-"
      }
      const searchableColumns = ['sum_amount_total', 'sum_amount_cash', 'sum_amount_pos', 'name', 'end', 'start', 'day']
      return Object.entries(item).some(([key, value]) => {
        if (value && typeof value === 'object') {
          return Object.entries(value).some(([nestedKey, nestedValue]) => {
            if (searchableColumns.includes(nestedKey)) {
              return nestedValue && nestedValue.toString().toLowerCase().includes(search)
            }
            return false
          })
        } else {
          if (searchableColumns.includes(key)) {
            return value && value.toString().toLowerCase().includes(search)
          }
        }

        return false
      })
    },
    setYear (year) {
      this.currentYear = year
    },
    openStopCashDialog (item) {
      this.selectedDisbursementOrder = item
      this.stopCashDialog = true
    },
    openAddCashCollectionDialog (item) {
      this.selectedDisbursementOrder = item
      this.addCashCollectionDialog = true
    },
    cancelDisbursementOrder (id) {
      this.$store.dispatch(`disbursement/${CANCEL_DISBURSEMENT_ORDER}`, id)
    },
    refreshDisbursementOrder () {
      this.$store.dispatch(`disbursement/${FETCH_DISBURSEMENT_ORDER_SINGLE}`, { url: this.selectedDisbursementOrder.disbursement.url })
    }
  },
  computed: {
    ...mapGetters("organization", ["currentOrganization"]),
    ...mapState(['loading']),
    years () {
      return ['Alle', ...new Set(this.items.map(item => item.end.split('-')[0]))]
    },
    filteredItemsByYear () {
      if (this.currentYear === 'Alle') {
        return this.items
      }
      return this.items.filter(item => {
        return item.end.includes(this.currentYear)
      })
    },
    effectiveOrganizationSettings () {
      return this.$store.getters["settings/effectiveSettingsForUrl"](this.currentOrganization.url)
    },
    allowCashPayments () {
      return this.effectiveOrganizationSettings.features?.allow_cash_payments || false
    },
  },
}
</script>

<style lang="scss" >
#disbursement-orders-table{
  .cancelled {
    color: #a7a7a7 !important;
    text-decoration: line-through;

  }
}
</style>
