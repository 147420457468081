<template>
  <v-container id="eval-disbursement-orders">
    <v-row>
      <v-col cols="12">
        <h2 class="mt-6">Übersicht über alle Kollektenmeldungen</h2>
        <p>Hier finden Sie alle beendeten Weiterleitungskollekten. Bitte schließen Sie diese zeitnah ab.</p>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="12" lg="12" class="">
       <eval-disbursement-parent-organization-table
          v-if="tableList.length > 0"
          :headers="headers"
          :items="tableList"
          @exportDataMethod="exportList"
          @downloadPdf="download"
       />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment"
import { mapGetters, mapState } from "vuex"
import { downloadBlob } from '@/lib/file-tools'
import EvalDisbursementParentOrganizationTable from "@/components/evaluation/EvalDisbursementParentOrganizationTable.vue"

export default {
  name: "EvalDisbursementParentList",
  components: { EvalDisbursementParentOrganizationTable },
  data () {
    return {
      donationReceiptRequests: [],
    }
  },
  computed: {
    ...mapState(["loading"]),
    ...mapState('location', ['locations']),
    ...mapGetters("organization", ["currentOrganization", "allOrganizations", "descendants"]),
    ...mapState("organization", ["organizations"]),
    ...mapGetters("collectionPlan", ["currentCollectionPlans", "collectionPlansLookup"]),
    ...mapState("disbursement", ["disbursementOrderStatistics"]),
    ...mapGetters("date", ["globalDatesByUrl"]),
    tableList () {
      if (!this.currentOrganization?.url || !this.disbursementOrderStatistics?.length || !this.collectionPlansLookup) {
        return []
      }
      return this.disbursementOrderStatistics.map(
        line => ({ ...line, plan_data: this.collectionPlansLookup[line.plan] ?? {}, number_of_children: (this.descendants[this.currentOrganization.url] ?? []).length + 1, })
      ).filter(line => !!line.plan_data?.url)
    },
    headers () {
      return [
        { text: "Sammlungsname", value: "plan_data.name" },
        { text: "Typ", value: "plan_data.display_type", filterable: false },
        // { text: "Geltungsbereich", value: "location", filterable: false },
        { text: "Liturgischer Tag", value: "plan_data.day_name" },
        { text: "Startdatum", value: "plan_data.start" },
        { text: "Enddatum", value: "plan_data.end" },
        // { text: "Spendensumme", value: "sum_amount_total", align: 'end' },
        { text: "Baranteil", value: "sum_amount_cash", align: 'end' },
        { text: "Online-Anteil", value: "sum_amount_ecom", align: 'end', width: '100' },
        { text: "Geräte-Anteil", value: "sum_amount_pos", align: 'end' },
        { text: "", value: "count_canceled", width: '100' },
        { text: "", value: "count_in_progress", width: '100' },
        { text: "", value: "count_transferred", width: '100' },
      ]
    },
  },
  methods: {
    async download ({ url, date }) {
      const response = await this.$store.getters.restApi.get(`${url}/download/`, { responseType: 'blob' })
      downloadBlob(response.data, `${date}_Auszahlungsanordnung_ID.pdf`, response.headers['content-type'])
    },
    async exportList () {
      const response = await this.$store.getters.restApi.get(`pdata/donation_receipt/xlsx/`, {
        responseType: 'blob',
        params: {
          organization: this.currentOrganization.id
        }
      })
      const now = moment().format("YYYY-MM-DD HH-MM-SS")
      downloadBlob(response.data, `${now}_${this.currentOrganization.name}_Angefragte Zuwendungsbestätigungen.xlsx`, response.headers['content-type'])
    },
  },
}
</script>
<style lang="stylus" scoped>

.v-data-table ::v-deep th
  font-size: 0.9em !important/*
.v-data-table ::v-deep td
  font-size: 0.9em !important*/
.archived
  color: #BDBDBD
</style>
