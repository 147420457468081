<template>
  <v-container id="eval-disbursement-orders" fluid>
    <v-row>
      <v-col cols="12">
        <h2 class="mt-6">Kollektenweiterleitung  & -meldung</h2>
        <p>Hier finden Sie alle beendeten Weiterleitungskollekten. Bitte schließen Sie diese zeitnah ab.</p>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="12" lg="12" class="">
        <eval-disbursement-orders-table
          v-if="tableList.length > 0"
          :headers="headers"
          :items="tableList"
          @exportDataMethod="exportList"
          @downloadPdf="download"
        />
        <p v-if="!hasPermission(currentOrganization.url, DISBURSEMENT_ORDER)">
          Sie haben nicht ausreichend Rechte, um diese Seite anzuzeigen. Wenden Sie sich an Ihren Administrator.
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment"
import { mapGetters, mapState } from "vuex"
import { downloadBlob } from '@/lib/file-tools'
import EvalDisbursementOrdersTable from "@/components/evaluation/EvalDisbursementOrdersTable"

import { shortenParish } from "@/lib/regex-tools"
import { DISBURSEMENT_ORDER } from '@/lib/permission-tools'
import { LEVEL_OPTIONS } from "@/lib/level-options"

export default {
  name: "EvalDisbursementChildList",
  components: { EvalDisbursementOrdersTable },
  data () {
    return {
      donationReceiptRequests: [],
      DISBURSEMENT_ORDER
    }
  },
  computed: {
    ...mapState(["loading"]),
    ...mapGetters('user', ['hasPermission']),
    ...mapState('location', ['locations']),
    ...mapGetters("organization", ["currentOrganization", "allOrganizations"]),
    ...mapGetters("collectionPlan", ["collectionPlansLookup"]),
    ...mapState("disbursement", ["disbursementOrders"]),
    ...mapGetters("date", ["globalDatesByUrl"]),

    tableList () {
      if (!this.currentOrganization || !this.currentOrganization.url) {
        return []
      }
      if (!this.collectionPlansLookup || Object.keys(this.collectionPlansLookup).length === 0) {
        return []
      }
      if (!this.disbursementOrders || !this.disbursementOrders.length) {
        return []
      }
      const combinedList = this.disbursementOrders.filter(disbursement => disbursement.organization === this.currentOrganization.url).map(disbursement => {
        const plan = this.collectionPlansLookup[disbursement.plan]
        const location = plan.location ? this.locations[plan.location]?.name : this.allOrganizations[plan.organization].name
        const organization = this.isGroupedView ? this.allOrganizations[disbursement.organization] : this.allOrganizations[disbursement?.handling_organization ?? disbursement.organization]
        return {
          id: plan.id,
          name: plan.name,
          url: plan.url,
          handling_organization: shortenParish(organization.name),
          organization_name: this.allOrganizations[plan.organization]?.name,
          type: plan.collection_variant,
          start: plan.start,
          end: plan.end,
          location: shortenParish(location),
          date: plan.date,
          day: this.globalDatesByUrl[plan.date]?.name,
          disbursement,
        }
      }
      )

      return combinedList.sort((a, b) => moment(a.end).isBefore(b.end) ? 1 : -1)
    },
    isGroupedView () {
      return [LEVEL_OPTIONS.REGION, LEVEL_OPTIONS.DISTRICT].includes(this.currentOrganization.level)
    },
    headers () {
      if (this.isGroupedView) {
        return [
          { text: "Sammlungsname", value: "name" },
          { text: "Typ", value: "type", filterable: false },
          { text: "Geltungsbereich", value: "location", filterable: false },
          { text: "Liturgischer Tag", value: "day" },
          { text: "Startdatum", value: "start" },
          { text: "Enddatum", value: "end" },
          { text: "Spendensumme", value: "disbursement.sum_amount", align: 'right' },
          { text: "Baranteil", value: "disbursement.sum_amount_cash", align: 'right' },
          { text: "Online-Anteil", value: "disbursement.sum_amount_ecom", align: 'right' },
          { text: "", value: "state", sortable: false, width: '20px' },
        ]
      }
      return [
        { text: "Sammlungsname", value: "name" },
        { text: "Typ", value: "type", filterable: false },
        { text: "Geltungsbereich", value: "location", filterable: false },
        { text: "Liturgischer Tag", value: "day" },
        { text: "Startdatum", value: "start" },
        { text: "Enddatum", value: "end" },
        { text: "Spendensumme", value: "disbursement.sum_amount_total", align: 'right' },
        { text: "Baranteil", value: "disbursement.sum_amount_cash", align: 'right' },
        { text: "Online-Anteil", value: "disbursement.sum_amount_ecom", align: 'right' },
        { text: "", value: "add_cash_action", sortable: false, width: '20px' },
        { text: "", value: "cancel_action", sortable: false, width: '20px' },
        { text: "", value: "download_pdf_action", sortable: false, width: '20px' },
        { text: "", value: "state", sortable: false, width: '20px' },
      ]
    },
  },
  methods: {
    async download ({ url, date }) {
      const response = await this.$store.getters.restApi.get(`${url}/download/`, { responseType: 'blob' })
      downloadBlob(response.data, `${date}_Auszahlungsanordnung_ID.pdf`, response.headers['content-type'])
    },
    async exportList () {
      const response = await this.$store.getters.restApi.get(`pdata/donation_receipt/xlsx/`, {
        responseType: 'blob',
        params: {
          organization: this.currentOrganization.id
        }
      })
      const now = moment().format("YYYY-MM-DD HH-MM-SS")
      downloadBlob(response.data, `${now}_${this.currentOrganization.name}_Angefragte Zuwendungsbestätigungen.xlsx`, response.headers['content-type'])
    },
    groupOrganizationsByParent: (organizations, allowedKeys) => {
      if (!organizations.length) {
        return []
      }

      function filterOrganization (org) {
        return Object.keys(org)
          .filter(key => allowedKeys.includes(key))
          .reduce((obj, key) => {
            obj[key] = org[key]
            return obj
          }, {})
      }
      const orgMap = {}

      organizations.forEach(org => {
        const filteredOrg = filterOrganization(org)
        filteredOrg.children = []
        orgMap[org.url] = filteredOrg
      })

      const topLevelOrgs = []

      organizations.forEach(org => {
        const filteredOrg = orgMap[org.url]
        if (org.parent && orgMap[org.parent]) {
          orgMap[org.parent].children.push(filteredOrg)
        } else {
          topLevelOrgs.push(filteredOrg)
        }
      })

      return topLevelOrgs
    },
    findOrganizationById (orgs, id) {
      for (const org of orgs) {
        if (org.id === id) {
          return org
        }
        if (org.children && org.children.length > 0) {
          const found = this.findOrganizationById(org.children, id)
          if (found) {
            return found
          }
        }
      }
      return null
    },
  },
}
</script>
<style lang="stylus" scoped>

.v-data-table ::v-deep th
  font-size: 0.9em !important/*
.v-data-table ::v-deep td
  font-size: 0.9em !important*/
.archived
  color: #BDBDBD
</style>
