<template>
  <div>
    <eval-disbursement-parent-list v-if="isParent" />
    <eval-disbursement-child-list v-else-if="isParish"  />
    <div v-else></div>
  </div>
</template>

<script>
import { LEVEL_OPTIONS } from '@/lib/level-options'
import {
  FETCH_LOCATIONS,
  FETCH_DATES_GLOBAL,
  FETCH_DISBURSEMENT_ORDER_STATISTIC,
  FETCH_DISBURSEMENT_ORDERS_FOR_ORGANIZATIONS,
  FETCH_ORGANIZATION_COLLECTION_PLANS
} from '@/store/action-types'
import EvalDisbursementChildList from './EvalDisbursementChildList.vue'
import EvalDisbursementParentList from "./EvalDisbursementParentList.vue"
import { mapGetters } from 'vuex'

export default {
  name: "EvalDisbursementOrders",
  data () {
    return {
      LEVEL_OPTIONS,
    }
  },
  components: {
    EvalDisbursementChildList,
    EvalDisbursementParentList
  },
  computed: {
    ...mapGetters('organization', ['currentOrganization']),
    isParish () {
      return [LEVEL_OPTIONS.PARISH, LEVEL_OPTIONS.OTHER].includes(this.currentOrganization.level)
    },
    isParent () {
      return [LEVEL_OPTIONS.DISTRICT, LEVEL_OPTIONS.REGION].includes(this.currentOrganization.level)
    },
  },
  async mounted () {
    await this.fetchData()
  },
  methods: {
    async fetchData () {
      const requests = [
        this.$store.dispatch('date/' + FETCH_DATES_GLOBAL),
        this.$store.dispatch('location/' + FETCH_LOCATIONS),
        this.$store.dispatch('collectionPlan/' + FETCH_ORGANIZATION_COLLECTION_PLANS, { url: this.currentOrganization.url, recursive: this.isParent }),
      ]
      if (this.isParent) {
        requests.push(
          this.$store.dispatch('disbursement/' + FETCH_DISBURSEMENT_ORDER_STATISTIC, { organizationId: this.currentOrganization.id })
        )
      } else {
        requests.push(
          this.$store.dispatch('disbursement/' + FETCH_DISBURSEMENT_ORDERS_FOR_ORGANIZATIONS, { organizationIds: [this.currentOrganization.id] }),
        )
      }
      await Promise.allSettled(requests)
    }
  },
  watch: {
    async currentOrganization () {
      await this.fetchData()
    }
  }
}
</script>
