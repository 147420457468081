import { render, staticRenderFns } from "./CollectionCard.vue?vue&type=template&id=962a0056&scoped=true&"
import script from "./CollectionCard.vue?vue&type=script&lang=js&"
export * from "./CollectionCard.vue?vue&type=script&lang=js&"
import style0 from "./CollectionCard.vue?vue&type=style&index=0&id=962a0056&prod&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "962a0056",
  null
  
)

export default component.exports